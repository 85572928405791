import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import Tel from "./telephone";
import Nav from "./nav";

import styles from "../../scss/layouts/header.module.scss"

const Header = ({ siteTitle }) => {

  const data = useStaticQuery(graphql`
    query {
      npLogo:file(relativePath: {eq: "np-logo-primary.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <header className={styles.header}>
      <div className={styles.header__inner}>
        <div className={styles.header__logo}>
         
            <a href="/">
              <Image fluid={data.npLogo.childImageSharp.fluid} alt={siteTitle}/>
            </a>
        
        </div>
        <div className={styles.header__items_wrap}>
            <Tel />
            <Nav />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}



export default Header
