	import React from "react";
	import { useStaticQuery, graphql } from "gatsby";
	import styles from "../scss/layouts/footer.module.scss";
	import logoRtpi from "../images/logoRTPI.png";
	import logoUkas from "../images/logoUkas.png";
	import logoNP from "../images/NP-Logo-white.png";
	import AddressIcon from "../images/svg/icon_location.inline.svg"
	import EmailIcon from "../images/svg/icon_email.inline.svg"
	import PhoneIcon from "../images/svg/icon_phone.inline.svg"
	import { Link } from "gatsby"

	export default () => {
	    const data = useStaticQuery(
	      graphql`
		query {
		  site {
		    siteMetadata {
		      title
		      companyVATNumber
		      companyTelephoneKnaresborough
		      companyNumber
		      companyEmail
		      companyAddress
		    }
		  }
		}
	      `
	    );

	    return (
	      <footer className={styles.footer}>
		<div className={styles.footer__inner}>
		    <div className={styles.footer__column}>
			<div className={styles.footer__images}>
			  <img src={logoNP} alt="Northern Planners"></img>
			</div>
			<p className={styles.footer__bio}>We are Northern Planners, a Yorkshire-based planning and design consultancy situated in North Yorkshire.</p>
		    </div>
		    <div className={styles.footer__column}>
			<div className={styles.footer__images}>
			  <img src={logoRtpi} alt="RTPI Logo"></img>
			</div>
			<div className={styles.footer__images}>
			  <img src={logoUkas} alt="UKAS Logo"></img>
			</div>
		    </div>
		    <div className={styles.footer__column}>
			<h3>Contact Us</h3>
			<div className={styles.footer__address}>
			<AddressIcon />
			  <address>{ data.site.siteMetadata.companyAddress }</address>
			</div>
			<div className={styles.footer__email}>
			   <EmailIcon />
			   <p><a href={ "mailto:"+data.site.siteMetadata.companyEmail } title={ "Email "+data.site.siteMetadata.companyEmail}>{ data.site.siteMetadata.companyEmail }</a></p>
			</div>
			<div className={styles.footer__phone}>
			  <PhoneIcon />
			  <p><a href={ "tel:"+data.site.siteMetadata.companyTelephoneKnaresborough } title={ "Call "+data.site.siteMetadata.companyTelephoneKnaresborough}>{ data.site.siteMetadata.companyTelephoneKnaresborough }</a></p>
                  </div>
            </div>
        </div>
        <div className={styles.footer__legal}>
            <div className={styles.footer__legal_inner}>
                <p><Link to="/legal/terms-conditions">Terms & Conditions</Link></p>
                <p><Link to="/legal/privacy-policy">Privacy Policy</Link></p>
                <p>&copy; { data.site.siteMetadata.title }</p>
            </div>
        </div>
      </footer>
    )
}
