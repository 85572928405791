import React from "react"
import { Link } from "gatsby"
import styles from "../../scss/layouts/navigation.module.scss"
import { useStaticQuery, graphql } from "gatsby";
import PhoneIcon from "../../images/svg/icon_phone.inline.svg"

export default () => {

  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        companyTelephoneKnaresborough
        companyTelephoneEastRiding
      }
    }
  }
`)

return (
  <>
    <a href="#main-menu"
       id="main-menu-toggle"
       className={styles.menu_toggle}
       aria-label="Open main menu">
      <span className={styles.sr_only}>Open main menu</span>
      <div className={styles.menu_toggle__open} aria-hidden="true"></div>
      <div className={styles.menu_toggle__open} aria-hidden="true"></div>
      <div className={styles.menu_toggle__open} aria-hidden="true"></div>
    </a>
    <nav className={styles.navigation} id="main-menu" aria-label="Main menu">
      <a href="#main-menu-toggle"
         id="main-menu-close"
         className={styles.menu_close}
         aria-label="Close main menu">
        <span className={styles.sr_only}>Close main menu</span>
        <div className={styles.menu_toggle__close} aria-hidden="true"></div>
        <div className={styles.menu_toggle__close} aria-hidden="true"></div>
        </a>
      <ol>
        <li><Link to="/about-us" activeClassName={styles.active}>About Us</Link></li>
        <li><Link to="/services" activeClassName={styles.active} partiallyActive={true}>Our Services</Link></li>
        <li><Link to="/projects" activeClassName={styles.active} partiallyActive={true}>Our Projects</Link></li>
        <li><Link to="/news" activeClassName={styles.active} partiallyActive={true}>News</Link></li>
        <li><Link to="/contact" activeClassName={styles.active}>Contact Us</Link></li>
        <li className={styles.navigation__tel_link}><a href={"tel:"+data.site.siteMetadata.companyTelephoneKnaresborough} title={"Call Knaresborough Office: "+data.site.siteMetadata.companyTelephoneKnaresborough}><PhoneIcon />Knaresborough: {data.site.siteMetadata.companyTelephoneKnaresborough}</a> </li>
        <li className={styles.navigation__tel_link}><a href={"tel:"+data.site.siteMetadata.companyTelephoneEastRiding} title={"Call East Riding Office: "+data.site.siteMetadata.companyTelephoneEastRiding}><PhoneIcon />East Riding: {data.site.siteMetadata.companyTelephoneEastRiding}</a> </li>
      </ol>
    </nav>
    <a href="#main-menu-toggle"
       className={styles.backdrop}
       tabIndex="-1"
       aria-hidden="true" hidden>Close main menu</a>
  </>

  )
}
