import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PhoneIcon from "../../images/svg/icon_phone.inline.svg"

import styles from "../../scss/layouts/telephone.module.scss"

export default () => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          companyTelephoneKnaresborough
          companyTelephoneEastRiding
        }
      }
    }
  `)

  return (
    <div className={styles.phone}>
      <div className={styles.phone__inner}>
        <div className={styles.phone__set}>
        <a href={"tel:"+data.site.siteMetadata.companyTelephoneKnaresborough} title={"Call our Office: "+data.site.siteMetadata.companyTelephoneKnaresborough}>
          <div className={styles.phone__label}>
            <PhoneIcon />Harrogate
          </div>
          <div className={styles.phone__number}>
            {data.site.siteMetadata.companyTelephoneKnaresborough}
          </div>
          </a>
        </div>
      </div>
    </div>
  )
}
